import { useRoutes } from "react-router-dom";
import { Provider } from "react-redux";
import { CacheProvider } from "@emotion/react";

import { ThemeProvider as MuiThemeProvider } from "@mui/material/styles";
// import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import jaLocale from "date-fns/locale/ja";

import "./i18n";
import createTheme from "./theme";
import routes from "./routes";
import useTheme from "./hooks/useTheme";
import { store } from "./redux/store";
import createEmotionCache from "./utils/createEmotionCache";
import { AuthProvider } from "./contexts/JWTContext";
import "./App.css";
import { RecoilProvider } from "./providers/recoilProvider";
import { useTracker } from "./hooks/useTracker";
// import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";

const clientSideEmotionCache = createEmotionCache();

function App({ emotionCache = clientSideEmotionCache }) {
  const content = useRoutes(routes);
  const { theme } = useTheme();

  useTracker();

  return (
    <RecoilProvider>
      <CacheProvider value={emotionCache}>
        <Provider store={store}>
          <LocalizationProvider dateAdapter={AdapterDateFns} locale={jaLocale}>
            <MuiThemeProvider theme={createTheme(theme)}>
              <AuthProvider>{content}</AuthProvider>
            </MuiThemeProvider>
          </LocalizationProvider>
        </Provider>
      </CacheProvider>
    </RecoilProvider>
  );
}

export default App;
